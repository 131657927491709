import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import Breadcrumb from 'components/Breadcrumb'
import CollapsibleContent from 'components/CollapsibleContent'
import RequestedProviderNotice from 'components/RequestedProviderNotice'
import FormattedMessage from 'components/FormattedMessage'

import CollapsibleText from 'apps/ProviderProfile/components/MainContent/CollapsibleText'
import DefaultText from 'apps/ProviderProfile/components/MainContent/DefaultText'
import Header from 'apps/ProviderProfile/components/MainContent/Header'
import Topics from 'apps/ProviderProfile/components/MainContent/Topics'
import Video from 'apps/ProviderProfile/components/MainContent/Video'

import HybridItems from 'apps/ProviderProfile/components/HybridItems'
import Reviews from 'apps/ProviderProfile/components/Reviews'
import Location from 'apps/ProviderProfile/components/Location'
import MoreLinkedProviders from 'apps/ProviderProfile/components//MoreLinkedProviders'

import 'apps/ProviderProfile/components/MainContent/index.less'

const AvailabilityCalendar = dynamic(
  () => import('apps/ProviderProfile/components/AvailabilityCalendar'),
  { ssr: false },
)

const ConsultantInformation = dynamic(
  () => import('components/ConsultantInformation'),
  { ssr: false },
)

const MainContent = (props) => {
  const {
    provider,
    breadcrumb,
    forceShowBreadcrumb,
  } = props
  const intl = useIntl()
  const isPortable = useSelector(state => state.layout.portable)
  const teamEvents = provider.eventIdeas?.filter(
    idea => idea.eventCategories.find(category => category.name === 'team_building'),
  )
  const eventPackages = provider.eventIdeas?.filter(
    idea => !idea.eventCategories.find(category => category.name === 'team_building'),
  )

  const hasTeamEvents = teamEvents && teamEvents.length > 0
  const hasEventPackages = eventPackages && eventPackages.length > 0

  return (
    <article id="provider-profile-page-content" className="provider-profile-page__content">
      {(isPortable || forceShowBreadcrumb) && (
        <>
          <Breadcrumb html={breadcrumb} />
          <RequestedProviderNotice provider={provider} />
        </>
      )}

      <CollapsibleContent collapsed={true} minHeight={295}>
        <Header
          title={provider.title}
          shortInfo={provider.shortInfo}
          rating={provider.rating}
          reviews={provider.reviews}
          address={provider.address}
        />
      </CollapsibleContent>

      <AvailabilityCalendar providerId={provider.id.toString()} />

      {hasTeamEvents > 0 && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={<FormattedMessage id="providerProfilePage.mainContent.linkedTeamEvents.all" />}
            providers={teamEvents}
            className="provider-profile-page__content"
          />
        </section>
      )}

      {hasEventPackages > 0 && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={<FormattedMessage id="providerProfilePage.mainContent.linkedEventPackages" />}
            providers={eventPackages}
            className="provider-profile-page__content"
          />
        </section>
      )}

      {provider.hybridItems?.length > 0 && (
        <section className="provider-profile-page__content__section">
          <HybridItems items={provider.hybridItems} hasHybridBadge={provider.hasHybridBadge} />
        </section>
      )}

      {provider.consultantInformation && (
        <section className="provider-profile-page__content__section">
          <ConsultantInformation provider={provider} />
        </section>
      )}

      {provider.orientation && provider.orientation.length > 0 && (
        <section className="provider-profile-page__content__section">
          <DefaultText
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.orientation' })}
            description={provider.orientation}
          />
        </section>
      )}

      {provider.essentialProperties && provider.essentialProperties.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.essentialProperties' })}
            topics={provider.essentialProperties}
          />
        </section>
      )}

      {provider.description && (
        <section className="provider-profile-page__content__section">
          <CollapsibleText
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.description' })}
            description={provider.description}
          />
        </section>
      )}

      {provider.technicalEquippings && provider.technicalEquippings.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.technicalEquippings' })}
            topics={provider.technicalEquippings}
          />
        </section>
      )}

      {provider.cateringOptions && provider.cateringOptions.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.cateringOptions' })}
            topics={provider.cateringOptions}
          />
        </section>
      )}

      {provider.amenities && provider.amenities.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.amenities' })}
            topics={provider.amenities}
          />
        </section>
      )}

      {provider.rating && (
        <section className="provider-profile-page__content__section" id="reviews" >
          <Reviews rating={provider.rating} reviews={provider.reviews} />
        </section>
      )}

      {provider.youtubeid && (
        <section className="provider-profile-page__content__section">
          <Video
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.video' })}
            id={provider.youtubeid}
          />
        </section>
      )}

      {provider.address && (
        <section className="provider-profile-page__content__section">
          <Location
            title={provider.title}
            address={provider.address}
          />
        </section>
      )}
    </article>
  )
}

MainContent.propTypes = {
  breadcrumb: PropTypes.string,
  forceShowBreadcrumb: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    rating: PropTypes.string,
    title: PropTypes.string,
    eventIdeas: PropTypes.array,
    address: PropTypes.object,
    youtubeid: PropTypes.string,
    cateringOptions: PropTypes.array,
    technicalEquippings: PropTypes.array,
    amenities: PropTypes.array,
    description: PropTypes.string,
    orientation: PropTypes.string,
    essentialProperties: PropTypes.array,
    hybridItems: PropTypes.array,
    hasHybridBadge: PropTypes.bool,
    shortInfo: PropTypes.string,
    type: PropTypes.string.isRequired,
    consultantInformation: PropTypes.object,
    reviews: PropTypes.array,
  }).isRequired,
}

export default MainContent
