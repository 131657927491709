import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import Button from 'components/Button'

import { addQueryStringParamsToUrl } from 'helpers/params'

const InquiryButton = (props) => {
  const intl = useIntl()

  const {
    className = '',
    queryParams,
    provider: {
      isConnectedToMeetingPackage,
      path,
      title,
      type,
    },
    ...restProps
  } = props

  let ctaTranslationKey = `providerProfile.cta.${type}`
  let ctaHref = addQueryStringParamsToUrl(`${path}/book`, queryParams, { removeEmptyParams: true })

  if (isConnectedToMeetingPackage) {
    ctaTranslationKey = 'providerProfile.cta.selectRoom'
    ctaHref = '#rooms'
  }

  return (
    <Button
      {...restProps}
      type="primary"
      title={intl.formatMessage(
        { id: 'providerProfilePage.navigationHeader.inquiryButtonTitle' },
        { title },
      )}
      href={ctaHref}
      translationKey={ctaTranslationKey}
      className={classNames('ant-btn--with-border-radius-small', className)}
    />
  )
}

InquiryButton.propTypes = {
  className: PropTypes.number,
  queryParams: PropTypes.shape({
    projectId: PropTypes.number,
  }),
  provider: PropTypes.shape({
    isConnectedToMeetingPackage: PropTypes.bool,
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default InquiryButton
