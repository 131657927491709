
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[section]/[city]/[slug]",
      function () {
        return require("private-next-pages/[section]/[city]/[slug]/index.jsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[section]/[city]/[slug]"])
      });
    }
  