import React from 'react'
import PropTypes from 'prop-types'

import CheckList, { CheckListItem } from '../CheckList'

import './Topics.less'

const Topics = ({ title, topics }) => (
  <div className="provider-profile-page__content__topics">
    <h3 className="provider-profile-page__content__headline">{title}</h3>

    <CheckList twoColumns={true}>
      {topics.map((topic, index) => (
        <CheckListItem key={index}>{topic}</CheckListItem>
      ))}
    </CheckList>
  </div>
)

Topics.propTypes = {
  title: PropTypes.string.isRequired,
  topics: PropTypes.array.isRequired,
}

export default Topics
