import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import classNames from 'classnames'
import { useRouter } from 'next/router'

import FormattedMessage from 'components/FormattedMessage'
import FormattedPrice from 'components/FormattedPrice'
import Button from 'components/Button'
import Icon from 'components/Icon'

import InquiryButton from '../InquiryButton'

import './index.less'

const BookWidget = (props) => {
  const {
    provider: {
      linkedLocation,
      price,
      type,
    },
  } = props

  const router = useRouter()
  const isEventIdea = type === 'EventIdea'
  const showSecondaryCta = linkedLocation && type === 'EventRoom'
  const queryParams = { projectId: router.query.projectId }

  return (
    <div className="provider-profile-page__book-widget">
      <Row className={classNames(
        'provider-profile-page__book-widget__row',
        {
          'provider-profile-page__book-widget__row--bordered': isEventIdea,
        },
      )}>
        <Col>
          <InquiryButton provider={props.provider} queryParams={queryParams} />

          {showSecondaryCta && (
            <Button
              translationKey="providerProfile.cta.secondary"
              type="link"
              href={`${linkedLocation.path}/book`}
            />
          )}
        </Col>
      </Row>

      {isEventIdea && (
        <Row gutter={16} type="flex" className="provider-profile-page__book-widget__row">
          <Col>
            <Icon name="tags" />
          </Col>
          <Col>
            <strong>
              <FormattedMessage id="providerProfile.bookWidget.priceFrom" />
              {' '}
              <FormattedPrice
                price={price}
                asInteger="auto" />
              {' '}
              <FormattedMessage id="providerProfile.bookWidget.pricePer.person" />
            </strong>
          </Col>
        </Row>
      )}
    </div>
  )
}

BookWidget.propTypes = {
  provider: PropTypes.shape({
    linkedLocation: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }),
    price: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default BookWidget
