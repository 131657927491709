import React from 'react'
import PropTypes from 'prop-types'

import Badge from 'components/Badge'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import Rating from 'components/Rating'

import { scrollToElement } from 'helpers/scroll'

import './Header.less'

const Header = (props) => {
  const {
    address,
    title,
    shortInfo,
    isInstantBookable,
    rating,
    reviews,
  } = props

  const scrollToSection = (sectionId) => () => {
    const section = document.getElementById(sectionId)

    if (section) {
      scrollToElement(section, null, { offset: -100 })
    }
  }

  const hasReviews = !!reviews?.length

  return (
    <div className="provider-profile-page__content__header">
      <h1
        className="provider-profile-page__content__header__headline"
      >
        {title}
      </h1>

      {address?.displayAddress && (
        <address
          className="provider-profile-page__content__header__address link link--gray"
          onClick={scrollToSection('location')}
        >
          <Icon name="map-marker" />
          {[
            address.street,
            address.postcode,
            address.cityWithDistrict,
          ].join(', ')}
        </address>
      )}

      {rating && (
        <div className="provider-profile-page__content__header__rating">
          <Rating className="provider-profile-page__content__header__rating__value" rating={rating} />

          {hasReviews && (
            <a
              onClick={scrollToSection('reviews')}
              className="provider-profile-page__content__header__rating__reviews-anchor link"
            >
              <FormattedMessage id="rating.reviews.count" values={{ count: reviews.length }} />
            </a>
          )}
        </div>
      )}

      {isInstantBookable && (
        <Badge type="skew" className="provider-profile-page__content__header__instant-bookable">
          <Icon name="instant-booking" />
          <span>
            <FormattedMessage id="shared.provider.instantBookable" />
          </span>
        </Badge>
      )}

      {shortInfo && (
        <h2 className="provider-profile-page__content__header__shortInfo">{shortInfo}</h2>
      )}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  shortInfo: PropTypes.string,
  isInstantBookable: PropTypes.bool,
  rating: PropTypes.string,
  reviews: PropTypes.array,
  address: PropTypes.shape({
    displayAddress: PropTypes.bool,
    street: PropTypes.string,
    postcode: PropTypes.string,
    cityWithDistrict: PropTypes.string,
  }),
}

export default Header
