import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { MeetingRoomProvider } from 'store/types/ProviderProfilePage'
import { HeaderData } from 'store/types/HeaderData'
import { RootState } from 'store/reducer'
import { LayoutState } from 'store/types/Layout'

import RequestedProviderNotice from 'components/RequestedProviderNotice'
import Sidebar from 'apps/ProviderProfile/components/Sidebar'
import Slider from 'apps/ProviderProfile/components/Slider'
import NavigationHeader from 'apps/ProviderProfile/components/NavigationHeader'
import MobileBookWidget from 'apps/ProviderProfile/components/MobileBookWidget'
import BookWidget from 'apps/ProviderProfile/components/BookWidget'

import MainContent from './MainContent'

type EventRoomState = {
  layout: LayoutState,
}

const EventRoom: React.FC<EventRoomProps> = (props) => {
  const {
    provider,
    provider: {
      canBeInquired,
      pictures,
      title,
    },
    headerData,
  } = props

  const {
    layout,
  } = useSelector<RootState, EventRoomState>(state => ({
    layout: state.layout,
  }), shallowEqual)

  return (
    <div>
      {!!pictures?.length && (
        <Slider pictures={pictures} title={title} />
      )}

      {(!layout.portable && headerData) && (
        <NavigationHeader
          provider={provider}
          breadcrumb={headerData.breadcrumbHtml}
        />
      )}

      <div className="provider-profile-page__content-wrapper">
        <MainContent provider={provider} breadcrumb={headerData?.breadcrumbHtml} />

        {!layout.portable && (
          <Sidebar>
            <RequestedProviderNotice provider={provider} />

            {canBeInquired && (
              <BookWidget provider={provider} />
            )}
          </Sidebar>
        )}
      </div>

      {layout.portable && (
        <MobileBookWidget provider={provider} />
      )}
    </div>
  )
}

interface EventRoomProps {
  provider: MeetingRoomProvider
  headerData?: HeaderData
}

export default EventRoom
