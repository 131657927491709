import React from 'react'
import PropTypes from 'prop-types'

import Error from 'components/Error'

import HeadlessLayout from 'layout/HeadlessLayout'

import { initialApiCall } from 'helpers/nextjsApiCall'
import { withApollo } from 'helpers/graphql'

import { actionCreators as providerProfilePageActions } from 'store/modules/ProviderProfilePage'

import ProviderProfile from 'apps/ProviderProfile'

const ProviderProfileLoader = withApollo((props) => {
  if (props.statusCode) {
    return <Error {...props} />
  }

  return <ProviderProfile />
})

ProviderProfileLoader.getInitialProps = async ({ reduxStore, req, res, asPath }) => {
  const initialResult = await initialApiCall(req, res, asPath, reduxStore)

  if (initialResult.success) {
    reduxStore.dispatch(providerProfilePageActions.setProviderDetails(initialResult))
  }

  return {
    forceEnableIntercom: true,
    ...initialResult,
  }
}

ProviderProfileLoader.lazyLoadSentry = true
ProviderProfileLoader.Layout = HeadlessLayout
ProviderProfileLoader.propTypes = {
  statusCode: PropTypes.number,
}

export default ProviderProfileLoader
