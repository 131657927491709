import moment from 'moment-timezone'

// TODO: find a way to translate this (dateFormat.default)
const defaultDateFormat = 'DD.MM.YYYY'

export const defaultTimeZone = 'Europe/Berlin'

type Range = {
  start?: moment.Moment
  end?: moment.Moment
}

const getMomentObjects = (dates: Array<moment.Moment | string>) => {
  if (!dates || !dates.length) {
    return undefined
  }

  return dates.map((date) => moment(date))
}

const formatRanges = (values: moment.Moment | moment.Moment[], join = true, dateFormat = defaultDateFormat) => {
  const ranges: Range[] = []

  if (!values) {
    return null
  }

  let lastValue: moment.Moment
  let nextRange: Range = {}

  const valuesArray = Array.isArray(values) ? values : [values]

  valuesArray.forEach((currentValue) => {
    if (!nextRange.start) {
      nextRange.start = currentValue
    } else {
      const diff = moment.duration(currentValue.diff(lastValue)).asDays()

      if (diff === 1) {
        nextRange.end = currentValue
      } else {
        ranges.push(nextRange)
        nextRange = {
          start: currentValue,
        }
      }
    }

    lastValue = currentValue
  })

  if (nextRange.start) {
    ranges.push(nextRange)
  }

  if (!ranges.length) {
    return null
  }

  const formattedRanges = ranges.map((range) => {
    if (range.start && range.end) {
      return `${range.start.format(dateFormat)} - ${range.end.format(dateFormat)}`
    } else {
      return `${range.start?.format(dateFormat)}`
    }
  })

  if (join) {
    return formattedRanges.join(', ')
  }

  return formattedRanges
}

const formatDate = (date: moment.Moment | string, dateFormat = defaultDateFormat, forceDefaultTimeZone = false) => {
  const momentDate = moment(date)

  if (forceDefaultTimeZone) {
    momentDate.tz(defaultTimeZone)
  }

  return momentDate.format(dateFormat)
}

const formatTime = (time: moment.Moment) => time.tz(defaultTimeZone).format('HH:mm')

const isDateInPast = (date: moment.Moment) => (
  date ? date.startOf('day').valueOf() < moment().startOf('day').valueOf() : false
)

const anyDateInThePast = (dates: moment.Moment[]) => dates.some((date) => isDateInPast(moment(date)))

const isDateTimePast = (dateTime: moment.Moment) => dateTime && moment(dateTime).isBefore(moment())

const isInDates = (searchDate: moment.Moment, dates: moment.Moment[] = []) => {
  for (const date of dates) {
    if (date.isSame(searchDate)) {
      return true
    }
  }

  return false
}

const combineDateAndTime = (
  date: moment.Moment | moment.Moment[] | string | string[],
  time = '00:00',
  useLocalTime = false,
) => {
  const momentDate = moment(Array.isArray(date) ? date[0] : date)
  const combinedDateTime = moment(
    `${momentDate.format('DD.MM.YYYY')} ${time}`,
    'DD.MM.YYYY HH:mm',
  )

  if (useLocalTime) { return combinedDateTime }

  return combinedDateTime.tz(defaultTimeZone, true)
}

const choosableTimes = [...new Array(24).keys()].reduce((times, hour) => {
  const formattedHour = `0${hour}`.slice(-2)

  const fullHour = `${formattedHour}:00`
  const halfHour = `${formattedHour}:30`

  times.push(
    { id: fullHour, text: fullHour },
    { id: halfHour, text: halfHour },
  )

  return times
}, [] as { id: string, text: string }[])

const sanitizeDateTimerange = (start: moment.Moment | string, end: moment.Moment | string) => {
  const momentObjects = getMomentObjects([start, end])

  if (!momentObjects) {
    return { date: null, start: null, end: null }
  }

  const [startDate, endDate] = momentObjects

  return {
    date: formatDate(startDate),
    start: formatTime(startDate),
    end: formatTime(endDate),
  }
}

export {
  choosableTimes,
  getMomentObjects,
  formatRanges,
  formatDate,
  isDateInPast,
  isDateTimePast,
  anyDateInThePast,
  isInDates,
  combineDateAndTime,
  sanitizeDateTimerange,
}
