import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import dynamic from 'next/dynamic'

import DefaultText from '../components/MainContent/DefaultText'
import CollapsibleText from '../components/MainContent/CollapsibleText'
import EventIdeaTopics from './EventIdeaTopics'

const ConsultantInformation = dynamic(
  () => import('components/ConsultantInformation'),
  { ssr: false },
)

class EventIdeaDescriptions extends React.PureComponent {
  render () {
    const {
      intl,
      provider,
    } = this.props

    return (
      <div className="provider-profile-page__event-ideas__descriptions">
        {provider.overviewText &&
          <DefaultText description={provider.overviewText} className="provider-profile-page__event-ideas__text-block" />
        }

        {provider.consultantInformation && (
          <section className="provider-profile-page__event-ideas__section">
            <ConsultantInformation provider={provider} />
          </section>
        )}

        {provider.eventIdeaData && (
          <section className="provider-profile-page__event-ideas__section">
            <EventIdeaTopics eventIdeaData={provider.eventIdeaData} />
          </section>
        )}

        {provider.detailText && (
          <section className="provider-profile-page__event-ideas__section">
            <CollapsibleText
              title={intl.formatMessage({ id: 'providerProfilePage.eventIdea.mainContent.detailText' })}
              description={provider.detailText}
            />
          </section>
        )}

        {provider.cateringText && (
          <section className="provider-profile-page__event-ideas__section">
            <CollapsibleText
              title={intl.formatMessage({ id: 'providerProfilePage.eventIdea.mainContent.cateringText' })}
              description={provider.cateringText}
            />
          </section>
        )}

        {provider.priceText && (
          <section className="provider-profile-page__event-ideas__section">
            <CollapsibleText
              title={intl.formatMessage({ id: 'providerProfilePage.eventIdea.mainContent.priceText' })}
              description={provider.priceText}
            />
          </section>
        )}

        {provider.locationText && (
          <section className="provider-profile-page__event-ideas__section">
            <CollapsibleText
              title={intl.formatMessage({ id: 'providerProfilePage.eventIdea.mainContent.locationText' })}
              description={provider.locationText}
            />
          </section>
        )}
      </div>
    )
  }
}

EventIdeaDescriptions.propTypes = {
  intl: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
}

export default injectIntl(EventIdeaDescriptions)
