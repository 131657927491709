import React from 'react'
import { useRouter } from 'next/router'

import classNames from 'helpers/css'

import { useGetProviderProfileQuery } from '../graphql.generated'

import FormattedMessage from 'components/FormattedMessage'
import FormattedPrice from 'components/FormattedPrice'
import Icon from 'components/Icon'

import styles from './index.module.scss'

const Prices: React.FC = () => {
  const {
    query: {
      slug,
    },
  } = useRouter()

  const { data } = useGetProviderProfileQuery({
    variables: {
      slug: slug as string,
    },
  })

  if (!data || !data?.provider) { return null }

  const provider = data.provider

  return (
    <div className={classNames(styles, 'provider-profile-page__content__prices')}>
      <h3 className={classNames(styles, 'provider-profile-page__content__headline')}>
        <FormattedMessage id="providerProfile.section.prices" />
      </h3>

      <div className={classNames(styles, 'provider-profile-page__content__prices__price-info')}>
        {PRICE_UNIT_KEYS.filter(key => !!provider[key]).map((priceKey) => (
          <div
            key={priceKey}
            className={classNames(styles, 'provider-profile-page__content__prices__price-info__detail')}
          >
            <div className={classNames(styles, 'provider-profile-page__content__prices__price-info__detail__price')}>
              <FormattedPrice
                price={Math.trunc(provider[priceKey])}
                asInteger="auto"
              />
            </div>
            <div className={classNames(styles, 'provider-profile-page__content__prices__price-info____detail__info')}>
              <FormattedMessage id={`providerProfile.section.prices.${priceKey}`} />
            </div>
          </div>
        ))}
      </div>

      {provider.minTurnover && (
        <div className={classNames(styles, 'provider-profile-page__content__prices__description')}>
          <Icon
            className={classNames(styles, 'provider-profile-page__content__prices__description__info-icon')}
            name="info-circle"
          />
          <FormattedMessage id="providerProfile.section.prices.minTurnover" />
          <FormattedPrice
            className={classNames(styles, 'provider-profile-page__content__prices__description__min-turnover-price')}
            price={Math.trunc(provider.minTurnover)}
            asInteger="auto"
          />
          <FormattedMessage id="providerProfile.section.prices.exclusive" />
        </div>
      )}
    </div>
  )
}

const PRICE_UNIT_KEYS: Array<PriceUnitKey> = [
  'halfDayRatePerRoom',
  'halfDayRatePerPerson',
  'dailyRatePerRoom',
  'dailyRatePerPerson',
  'hourlyRatePerRoom',
]

type PriceUnitKey = 'halfDayRatePerRoom'
  | 'halfDayRatePerPerson'
  | 'dailyRatePerRoom'
  | 'dailyRatePerPerson'
  | 'hourlyRatePerRoom'

export default Prices
