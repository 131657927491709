import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ProviderCard from 'components/ProviderCard'

import './index.less'

const MoreProviders = ({ className, providers, title }) => (
  <article className={classNames('provider-profile-page__more-providers', className)}>
    <h2 className="provider-profile-page__title">{title}</h2>

    <div className="provider-profile-page__more-providers__list">
      {providers.map((provider) => (
        <ProviderCard
          key={provider.id}
          provider={provider}
        />
      ))}
    </div>
  </article>
)

MoreProviders.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
}

export default MoreProviders
