import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import EventIdeaMainContent from './MainContent'

import RequestedProviderNotice from 'components/RequestedProviderNotice'

import MobileBookWidget from 'apps/ProviderProfile/components/MobileBookWidget'
import NavigationHeader from 'apps/ProviderProfile/components/NavigationHeader'
import Sidebar from 'apps/ProviderProfile/components/Sidebar'
import Slider from 'apps/ProviderProfile/components/Slider'
import InquiryDisabledMessage from 'apps/ProviderProfile/components/InquiryDisabledMessage'
import BookWidget from 'apps/ProviderProfile/components/BookWidget'

const EventIdeaProfilePage = (props) => {
  const {
    provider,
    headerData,
  } = props

  const portable = useSelector(state => state.layout.portable)

  return (
    <div>
      {provider.pictures && provider.pictures.length && (
        <Slider pictures={provider.pictures} title={provider.title} />
      )}

      {!portable && headerData &&
        <NavigationHeader
          provider={provider}
          breadcrumb={headerData.breadcrumbHtml}
        />
      }

      <div className="provider-profile-page__content-wrapper">
        <EventIdeaMainContent
          provider={provider}
          breadcrumb={headerData && headerData.breadcrumbHtml}
        />

        {!portable && (provider.price || provider.address) && (
          <Sidebar>
            <RequestedProviderNotice provider={provider} />

            {provider.canBeInquired && provider.price && (
              <BookWidget provider={provider} />
            )}

            {!provider.canBeInquired && <InquiryDisabledMessage link={provider.filterPageUrl} />}
          </Sidebar>
        )}
      </div>

      {portable && provider.price && (
        <MobileBookWidget provider={provider} />
      )}
    </div>
  )
}

EventIdeaProfilePage.propTypes = {
  provider: PropTypes.object.isRequired,
  headerData: PropTypes.object,
}

export default EventIdeaProfilePage
