import { useQuery } from '@apollo/client'

import { GET_FLIPPER_QUERY } from './graphql'

// this is an experimental feature and may need improvement
const useFlipper = (id: string): boolean => {
  const { data } = useQuery(GET_FLIPPER_QUERY, { variables: { id } })

  return data?.flipperFeature.enabled
}

export default useFlipper
