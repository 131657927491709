import React from 'react'
import PropTypes from 'prop-types'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import CheckList, { CheckListItem } from '../CheckList'

import './index.less'

const HybridItems = ({ items, hasHybridBadge }) => (
  <div className="provider-profile-page__content__hybrid-items">
    <h3 className="provider-profile-page__content__headline">
      <FormattedMessage id="providerProfilePage.mainContent.hybridItems.headline" />

      {hasHybridBadge && (
        <Icon name="camera" />
      )}
    </h3>

    <p className="provider-profile-page__content__hybrid-items__description">
      <FormattedMessage
        id="providerProfilePage.mainContent.hybridItems.description"
        values={{
          link: chunks => (
            <a
              className="link"
              href="https://about.eventinc.de/virtual-hybrid-badge/"
              target="_blank"
            >
              {chunks}
            </a>
          ),
        }}
      />
    </p>

    <CheckList twoColumns={true}>
      {items.map((item, index) => (
        <CheckListItem key={index}>
          <FormattedMessage id={`providerProfilePage.mainContent.hybridItems.items.${item}`} />
        </CheckListItem>
      ))}
    </CheckList>
  </div>
)

HybridItems.propTypes = {
  items: PropTypes.array.isRequired,
  hasHybridBadge: PropTypes.bool,
}

export default HybridItems
