import React from 'react'

import FormattedMessage from 'components/FormattedMessage'
import Hint, { HintVariantType } from 'components/Hint'
import { ProjectDashboardStatus } from 'generated/types'

const statusToVariant: {[status in ProjectDashboardStatus]: HintVariantType} = {
  proposed: 'notice',
  open: 'attention',
  waiting: 'attention',
  interesting: 'success',
  offer_binding_without_option: 'success',
  offer_first_option: 'success',
  offer_second_option: 'success',
  price_indication: 'success',
  booked: 'success',
  offer_accepted: 'success',
  cancelled: 'error',
  declined: 'error',
  declined_by_provider: 'error',
}

const RequestStatusHint: React.FC<RequestStatusHintProps> = ({ requestStatus, className }) => (
  <Hint variant={statusToVariant[requestStatus]} className={className}>
    <FormattedMessage id={`providerProfilePage.navigationHeader.hint.${requestStatus}`} />
  </Hint>
)

type RequestStatusHintProps = {
  requestStatus: ProjectDashboardStatus
  className: string
}

export default RequestStatusHint
