const getRange = (values) => {
  const min = Math.min.apply(null, values)
  const max = Math.max.apply(null, values)

  return {
    min: isFinite(min) ? min : undefined,
    max: isFinite(max) ? max : undefined,
  }
}

const formatPrice = (intl, price) => {
  const roundedPrice = parseInt(price)
  const formattedPrice = roundedPrice === parseFloat(price)
    ? roundedPrice
    : intl.formatNumber(price, { minimumFractionDigits: 2 })

  return formattedPrice
}

const parseBudgetRange = (eventBudget) => {
  if (!eventBudget) {
    return [null, null]
  }

  let bounds = eventBudget.match(/([,.0-9]+)/g)

  if (!bounds) {
    return [null, null]
  }

  bounds = bounds.map((num) => parseInt(num.replace(/[,.]+/g, '')))

  if (bounds.length === 2) {
    return bounds
  }

  return bounds[0] < 10000 ? [null, bounds[0]] : [bounds[0], null]
}

export {
  getRange,
  formatPrice,
  parseBudgetRange,
}
