import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'

import { actionCreators as providerProfilePageActions } from 'store/modules/ProviderProfilePage'

import EventLocationProfilePage from './EventLocation'
import EventIdeaProfilePage from './EventIdea'
import EventSupplierProfilePage from './EventSupplier'
import EventRoomProfilePage from './EventRoom'

import Header from 'components/Header'
import Spinner from 'components/Spinner'
import PageHead from 'containers/PageHead'
import ProviderSchemaOrg from 'components/SchemaOrg'

import { pushDataLayer } from 'helpers/googleAnalytics'
import { addQueryStringParamsToUrl } from 'helpers/params'

import './index.less'

const mapStateToProps = (state) => ({
  ...state.providerProfilePage,
})

const mapDispatchToProps = {
  ...providerProfilePageActions,
}

class ProviderProfilePage extends React.PureComponent {
  componentDidMount () {
    this.pushProductDetailEvent()
  }

  componentDidUpdate (prevProps) {
    if (this.props.provider.id !== prevProps.provider.id) {
      this.pushProductDetailEvent()
    }
  }

  pushProductDetailEvent = () => {
    pushDataLayer({
      event: 'eeProductDetail',
    })
  }

  renderProviderProfilePageContent = () => {
    const {
      provider,
      headerData,
      router,
    } = this.props

    const projectId = Number(router.query?.projectId)

    if (provider.type === 'EventLocation') {
      return <EventLocationProfilePage projectId={projectId} provider={provider} headerData={headerData} />
    }

    if (provider.type === 'EventIdea') {
      return <EventIdeaProfilePage projectId={projectId} provider={provider} headerData={headerData} />
    }

    if (provider.type === 'EventSupplier') {
      return <EventSupplierProfilePage projectId={projectId} provider={provider} headerData={headerData} />
    }

    if (provider.type === 'EventRoom') {
      return <EventRoomProfilePage projectId={projectId} provider={provider} headerData={headerData} />
    }
  }

  onProjectSelect = (projectId) => {
    const { router } = this.props

    const url = addQueryStringParamsToUrl(window.location, { projectId }, { removeEmptyParams: true })

    router.push(url)
  }

  render () {
    const {
      provider,
      loading,
      headerData,
    } = this.props

    if (loading) {
      return <Spinner fullPageHeight />
    }

    if (provider.id === null) { return null }

    return (
      <article className="provider-profile-page">
        {headerData && <PageHead headerData={headerData} />}

        <Header alreadyWrappedWithApollo onProjectSelect={this.onProjectSelect} />

        {this.renderProviderProfilePageContent()}

        <ProviderSchemaOrg provider={provider} />
      </article>
    )
  }
}

ProviderProfilePage.propTypes = {
  router: PropTypes.shape({
    asPath: PropTypes.string.isRequired,
    query: PropTypes.shape({
      projectId: PropTypes.string,
    }).isRequired,
    push: PropTypes.func.isRequired,
  }),
  provider: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  headerData: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderProfilePage))
