import React from 'react'
import classNames from 'classnames'

import Icon from 'components/Icon'

import './index.less'

const Hint: React.FC<HintProps> = ({
  iconName = 'info-circle-bordered',
  children,
  className = '',
  variant = 'neutral',
  modifiers = [],
  spin,
}) => (
  <div
    className={classNames(
      'hint',
      `hint--${variant}`,
      modifiers.map((modifier) => `hint--${modifier}`),
      className,
    )}
  >
    <Icon name={iconName} className={classNames(spin && 'icon-spin')} />
    <div className="hint__content">{children}</div>
  </div>
)

type HintProps = {
  iconName?: string,
  children: React.ReactNode,
  className?: string,
  variant?: HintVariantType,
  modifiers?: Array<HintModifiersType>,
  spin?: boolean
}

type HintModifiersType = 'bold'
export type HintVariantType = 'neutral' | 'notice' | 'error' | 'success' | 'attention' | 'navy'

export default Hint
