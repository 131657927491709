import React from 'react'
import PropTypes from 'prop-types'

import './index.less'

class Breadcrumb extends React.PureComponent {
  render () {
    const {
      html,
    } = this.props

    function renderBreadcrumb () {
      return { __html: html }
    }

    return (
      <div
        className="breadcrumb"
        dangerouslySetInnerHTML={renderBreadcrumb()}
      />
    )
  }
}

Breadcrumb.propTypes = {
  html: PropTypes.string.isRequired,
}

export default Breadcrumb
