import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import classNames from 'classnames'

import { IntlMessageKeys } from 'components/FormattedMessage/types'
import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import SlideDownTransition from 'components/SlideDownTransition'

import './index.less'

type RefType = { open:() => void }

const CollapsibleContent = React.forwardRef<RefType, React.PropsWithChildren<CollapsibleContentProps>>(
  (props, ref) => {
    const {
      toggleText = {
        readMore: 'shared.collapsibleContent.readMore',
        readLess: 'shared.collapsibleContent.readLess',
      },
      textPosition = 'bottom',
      fadeoutText,
      showToggleButton = true,
      showToggleIcon = true,
      className,
      childrenCollapsedProp,
      children,
    } = props

    const collapsibleElement = useRef<HTMLDivElement>(null)
    const [collapsed, setCollapsed] = useState(props.collapsed)
    const [elementHeight, setElementHeight] = useState<number>()
    const [minHeight, setMinHeight] = useState(props.minHeight || 0)

    useImperativeHandle(ref, () => ({
      open: () => setCollapsed(false),
    }))

    useEffect(() => {
      if (!collapsibleElement.current) { return }

      const elementHeight = collapsibleElement.current.getBoundingClientRect().height

      setElementHeight(elementHeight)

      if (elementHeight < minHeight) {
        setMinHeight(elementHeight)
        setCollapsed(false)
      }
    }, [children])

    const isToggleButtonVisible = elementHeight && showToggleButton && elementHeight > minHeight

    const toggleButton = isToggleButtonVisible && (
      <a
        className="collapsible-content__toggle-button"
        onClick={() => { setCollapsed(!collapsed) }}
      >
        {showToggleIcon && <Icon name={collapsed ? 'angle-down' : 'angle-up'} options={['2x']} />}
        <FormattedMessage id={collapsed ? toggleText.readMore : toggleText.readLess} />
      </a>
    )

    const renderChildren = () => {
      if (!childrenCollapsedProp) {
        return children
      }

      return React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          const props = { [childrenCollapsedProp]: collapsed }

          return React.cloneElement(child, props)
        }

        return child
      })
    }

    return (
      <div className={classNames('collapsible-content', className)}>
        {textPosition === 'top' && toggleButton}

        <div
          className={classNames(
            'collapsible-content__content',
            {
              'collapsible-content__content--fadeout-text': fadeoutText,
              'collapsible-content__content--collapsed': collapsed,
            },
          )}
        >
          <SlideDownTransition
            duration={500}
            exitTimeout={50}
            collapsed={collapsed}
            minHeight={minHeight}
          >
            <div
              className="collapsible-content__content__text"
              ref={collapsibleElement}
            >
              {renderChildren()}
            </div>
          </SlideDownTransition>
        </div>

        {textPosition === 'bottom' && toggleButton}
      </div>
    )
  },
)

type CollapsibleContentProps = {
  childrenCollapsedProp?: string | undefined,
  className?: string | undefined,
  collapsed: boolean,
  showToggleButton?: boolean | undefined,
  showToggleIcon?: boolean | undefined,
  minHeight?: number | undefined,
  toggleText?: {
    readMore: IntlMessageKeys,
    readLess: IntlMessageKeys,
  } | undefined,
  textPosition?: string | undefined,
  fadeoutText?: boolean,
}

export default CollapsibleContent
