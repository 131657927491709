import React from 'react'
import PropTypes from 'prop-types'
import Transition from 'react-transition-group/Transition'
import merge from 'lodash/merge'

class SlideDownTransition extends React.PureComponent {
  render () {
    const {
      children,
      duration,
      enterTimeout,
      exitTimeout,
      onTransitionStart,
      onTransitionEnd,
      collapsed,
      additionalStyles,
      minHeight,
      assumedHeight,
      ...restProps
    } = this.props

    const defaultStyle = {
      transition: `max-height ${duration}ms ease-in-out`,
      maxHeight: 0,
      minHeight,
    }

    const transitionStyles = merge({
      entering: {
        maxHeight: `${assumedHeight}px`,
        overflow: 'hidden',
      },
      entered: {
        transition: 'max-height 0ms',
        maxHeight: 'none',
      },
      exiting: {
        transition: 'max-height 0ms',
        maxHeight: this.collapsedPart ? this.collapsedPart.offsetHeight : `${assumedHeight}px`,
        overflow: 'hidden',
      },
      exited: {
        overflow: 'hidden',
      },
    }, additionalStyles)

    return (
      <Transition
        in={!collapsed}
        timeout={{
          enter: typeof enterTimeout === 'number' ? enterTimeout : duration,
          exit: typeof exitTimeout === 'number' ? exitTimeout : duration,
        }}
        onEnter={() => {
          if (onTransitionStart) { onTransitionStart() }
        }}
        onExit={() => {
          if (onTransitionStart) { onTransitionStart() }
        }}
        addEndListener={(node) => {
          if (onTransitionEnd) {
            node.addEventListener('transitionend', onTransitionEnd, { once: true })
          }
        }}
      >
        {(state) => (
          <div
            {...restProps}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={(div) => { this.collapsedPart = div }}
          >
            {children}
          </div>
        )}
      </Transition>
    )
  }
}

SlideDownTransition.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  collapsed: PropTypes.bool,
  duration: PropTypes.number.isRequired,
  enterTimeout: PropTypes.number,
  exitTimeout: PropTypes.number,
  additionalStyles: PropTypes.object,
  assumedHeight: PropTypes.number,
  minHeight: PropTypes.number,
  onTransitionStart: PropTypes.func,
  onTransitionEnd: PropTypes.func,
}

SlideDownTransition.defaultProps = {
  assumedHeight: 1000,
  minHeight: 0,
}

export default SlideDownTransition
