import React from 'react'
import PropTypes from 'prop-types'

import { Row, Col } from 'antd'

import FormattedMessage from 'components/FormattedMessage'
import Rating from 'components/Rating'
import CollapsibleContent from 'components/CollapsibleContent'

import Review from './Review'

import './index.less'

const Reviews = ({ reviews, rating }) => {
  const showReviews = !!reviews?.length

  return (
    <div className="provider-profile-page__reviews">
      <Row>
        <Col md={9} xs={24} className="provider-profile-page__reviews__column">
          <h3 className="provider-profile-page__reviews__column__headline">
            <FormattedMessage id="providerProfilePage.mainContent.review.rating" />
          </h3>

          <Rating
            className="provider-profile-page__reviews__column__total-rating"
            rating={rating}
          />

          {!showReviews && (
            <div className="provider-profile-page__reviews__column__no-reviews">
              <FormattedMessage id="providerProfilePage.mainContent.review.empty" />
            </div>
          )}
        </Col>

        {showReviews && (
          <Col md={15} xs={24} className="provider-profile-page__reviews__column">
            <h4 className="provider-profile-page__reviews__column__headline">
              <FormattedMessage id="providerProfilePage.mainContent.review.recent" />
              {` (${reviews.length})`}
            </h4>

            <CollapsibleContent
              minHeight={340}
              collapsed
              className="provider-profile-page__reviews__column__reviews-list"
              fadeoutText={true}
              toggleText={{
                readMore: 'providerProfilePage.mainContent.review.all',
                readLess: 'providerProfilePage.mainContent.review.recent',
              }}
            >
              {reviews.map((review, key) => (
                <Review
                  key={key}
                  name={review.displayName}
                  createdAt={review.createdAt}
                  rating={review.rating}
                  text={review.text}
                />
              ))}
            </CollapsibleContent>
          </Col>
        )}
      </Row>
    </div>
  )
}

Reviews.propTypes = {
  rating: PropTypes.string.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      rating: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
}

export default Reviews
