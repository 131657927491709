import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'

import MoreProviders from 'apps/ProviderProfile/components/MoreProviders'

const mapStateToProps = (state) => ({
  layout: state.layout,
})

const SimilarProviders = (props) => {
  const {
    layout,
    providerTitle,
    providerCity,
    providerType,
    similarProviders,
  } = props

  let maxSimilarProviderCount = 0

  if (layout.desktop) { maxSimilarProviderCount = 3 }
  if (layout.tablet) { maxSimilarProviderCount = 2 }
  if (layout.mobile) { maxSimilarProviderCount = 0 }

  const similarProvidersToRender = similarProviders.slice(0, maxSimilarProviderCount)

  if (similarProvidersToRender.length === 0) {
    return null
  }

  return (
    <MoreProviders
      title={(
        <FormattedMessage
          id="providerProfilePage.mainContent.similarLocations"
          values={{
            providerType: <FormattedMessage id={`providerType.${providerType}.plural`} />,
            locationName: providerTitle,
            locationCity: providerCity,
          }}
        />
      )}
      providers={similarProvidersToRender}
    />
  )
}

SimilarProviders.propTypes = {
  layout: PropTypes.object.isRequired,
  providerTitle: PropTypes.string.isRequired,
  providerCity: PropTypes.string.isRequired,
  providerType: PropTypes.string.isRequired,
  similarProviders: PropTypes.array.isRequired,
}

export default connect(mapStateToProps)(SimilarProviders)
