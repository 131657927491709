import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Icon'
import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'

import './InquiryDisabledMessage.less'

const InquiryDisabledMessage = ({ link }) => (
  <div className="provider-profile-page__inquiry-disabled">
      <div>
        <Icon
          name="calendar-times-o"
          options={['2x']}
        />
      </div>
      <FormattedMessage
        id="providerProfilePage.sidebar.inquiryNotPossible"
        values={{
          span: chunks => <span className="provider-profile-page__inquiry-disabled__info">{chunks}</span>,
          link: chunks => (
            <Button
              type="primary"
              href={link}
              className={classNames('ant-btn--with-border-radius-small')}
            >
              {chunks}
            </Button>
          ),
        }}
      />
  </div>
)

InquiryDisabledMessage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default InquiryDisabledMessage
