import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import classNames from 'classnames'
import upperFirst from 'lodash/upperFirst'

import Icon from 'components/Icon'

import { pushDataLayer } from 'helpers/googleAnalytics'

import './index.less'

class ImageGallery extends React.PureComponent {
  constructor (props) {
    super(props)

    this.ref = null
    this.currentIndex = 0 // not used inside this component, but to get the current index from outside

    const {
      pictures,
      arrows,
      fade,
      initialSlide,
      variableWidth,
      adaptiveHeight,
      trackGalleryUsage,
      lazyLoad,
    } = this.props

    let setInitialSlide = initialSlide > 0 ? initialSlide : 0

    if (!fade && !lazyLoad && pictures.length === 1) {
      setInitialSlide = 0
    }

    this.sliderSettings = {
      lazyLoad: lazyLoad ? 'ondemand' : null,
      speed: 600,
      fade: !!fade,
      nextArrow: <Icon name="angle-right" />,
      prevArrow: <Icon name="angle-left" />,
      arrows: typeof arrows === 'undefined' ? (pictures.length > 1) : arrows,
      swipe: (pictures.length > 1),
      initialSlide: setInitialSlide,

      // part of PWH-1294, enlarges the width of the image by 2px (-1px on each side), so we can move it 1px to the left
      centerMode: true,
      centerPadding: '-1px',

      variableWidth,
      adaptiveHeight,

      beforeChange: (oldIndex, newIndex) => {
        this.currentIndex = newIndex

        const diff = newIndex - oldIndex
        const diffAbs = Math.abs(diff)
        let arrowDirection = ''

        // If fade is enabled, oldIndex and newIndex is the same (bug in react-slick?)
        // so we assume when fading, we always fade to the next image, and not the previous one
        // TODO: after updating react-slick, check if the bug still exists and if not, remove `fade || `
        if (fade || diffAbs === 1) {
          if (fade || newIndex > oldIndex) {
            arrowDirection = 'right'
          } else {
            arrowDirection = 'left'
          }
        } else {
          if (diff > 0) {
            arrowDirection = 'left'
          } else {
            arrowDirection = 'right'
          }
        }

        if (trackGalleryUsage && arrowDirection) {
          pushDataLayer({
            event: 'googleAnalyticsGenericEvent',
            eventCategory: 'galleryArrow',
            eventAction: `click${upperFirst(arrowDirection)}`,
          })
        }
      },
    }

    this.onLoad = this.onLoad.bind(this)
    this.showPreviousImage = this.showPreviousImage.bind(this)
    this.showNextImage = this.showNextImage.bind(this)
  }

  componentDidMount () {
    window.setTimeout(() => {
      if (this.ref && this.ref.innerSlider) { this.ref.innerSlider.onWindowResized() }
    })
  }

  onLoad (index) {
    this.ref.innerSlider.onWindowResized()

    if (index === this.props.initialSlide && this.props.onFirstImageLoaded) {
      this.props.onFirstImageLoaded()
    }
  }

  getAltText (title, index) {
    return title ? `${title} ${index + 1}` : undefined
  }

  hasMultipleImages () {
    return this.props.pictures && this.props.pictures.length > 1
  }

  showPreviousImage () {
    if (this.hasMultipleImages()) {
      this.ref.slickPrev()
    }
  }

  showNextImage () {
    if (this.hasMultipleImages()) {
      this.ref.slickNext()
    }
  }

  renderBackgroundPicture (picture) {
    const fallbackImageUrl = picture.url
    const imageUrl = picture.webp_url ? picture.webp_url : fallbackImageUrl

    // The key is prefixed with "img-" because of a bug in react-slick.
    return (
      <div
        className={classNames(
          `img-${picture.id}`,
          'slick-slide__background-image',
        )}
        key={`img-${picture.id}`}
      >
        <style>
          {
            `.webp .img-${picture.id}{background-image:url(${imageUrl})}` +
            `.no-webp .img-${picture.id}{background-image:url(${fallbackImageUrl})}`
          }
        </style>
      </div>
    )
  }

  renderPicture (picture, index) {
    const { title } = this.props

    const fallback = (
      <img
        src={picture.url}
        onLoad={() => { this.onLoad(index) }}
        alt={this.getAltText(title, index)}
      />
    )

    // The key is prefixed with "img-" because of a bug in react-slick.
    if (!picture.webp_url) {
      return (
        <div key={`img-${picture.id}`}>
          {fallback}
        </div>
      )
    }

    return (
      <picture
        key={`img-${picture.id}`}
        onLoad={() => { this.onLoad(index) }}
      >
        <source type="image/webp" srcSet={picture.webp_url} />
        <source type="image/jpeg" srcSet={picture.url} />

        {fallback}
      </picture>
    )
  }

  render () {
    const {
      pictures,
      isInsideCard,
      asBackground,
    } = this.props

    const galleryContent = pictures.map((picture, index) => (
      // The key is prefixed with "img-" because of a bug in react-slick.
      asBackground ? this.renderBackgroundPicture(picture) : this.renderPicture(picture, index)
    ))

    return (
      <Slider
        {...this.sliderSettings}
        ref={(ref) => {
          this.ref = ref
        }}
        className={classNames(
          'image-gallery',
          {
            'slick-slider--with-one-image': pictures.length === 1,
            'slick-slider--inside-card': isInsideCard,
            'slick-slider--image-as-background': asBackground,
          },
        )}
      >
        {galleryContent}
      </Slider>
    )
  }
}

ImageGallery.defaultProps = {
  lazyLoad: true,
}

ImageGallery.propTypes = {
  pictures: PropTypes.array,
  arrows: PropTypes.bool,
  fade: PropTypes.bool,
  initialSlide: PropTypes.number,
  variableWidth: PropTypes.bool,
  adaptiveHeight: PropTypes.bool,
  onFirstImageLoaded: PropTypes.func,
  title: PropTypes.string,
  isInsideCard: PropTypes.bool,
  asBackground: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  trackGalleryUsage: PropTypes.bool,
}

export default ImageGallery
