import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Row, Col } from 'antd'

import CollapsibleContent from 'components/CollapsibleContent'

import LinkedProviderCard from '../LinkedProviderCard'

import './index.less'

const NON_COLLAPSED_PROVIDERS_COUNT = 4

const MoreLinkedProviders = (props) => {
  const {
    className,
    title,
    providers,
  } = props

  const nonCollapsedProviders = providers.slice(0, NON_COLLAPSED_PROVIDERS_COUNT)
  const collapsedProviders = providers.slice(NON_COLLAPSED_PROVIDERS_COUNT)

  return (
    <article className={classNames('provider-profile-page__more-linked-providers', className)}>
      <h3 className="provider-profile-page__content__headline">{title}</h3>

      <Row type="flex" gutter={[16, 24]}>
        {nonCollapsedProviders.map((provider) => (
          <Col md={12} sm={12} xs={24} key={provider.id}>
            <LinkedProviderCard provider={provider} />
          </Col>
        ))}
      </Row>

      {collapsedProviders.length > 0 && (
        <CollapsibleContent
          collapsed
          toggleText={{
            readMore: 'common.readMore',
            readLess: 'common.readLess',
          }}
        >
          <Row type="flex" gutter={[16, 24]}>
            {collapsedProviders.map((provider) => (
              <Col md={12} sm={12} xs={24} key={provider.id}>
                <LinkedProviderCard provider={provider} />
              </Col>
            ))}
          </Row>
        </CollapsibleContent>
      )}
    </article>
  )
}

MoreLinkedProviders.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  })).isRequired,
}

export default MoreLinkedProviders
