import { gql } from '@apollo/client'

export const GET_FLIPPER_QUERY = gql`
  query FlipperFeature($id: ID!) {
    flipperFeature(id: $id) {
      id
      enabled
    }
  }
`
