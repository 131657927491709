import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import RequestedProviderNotice from 'components/RequestedProviderNotice'

import SimilarProviders from '../EventLocation/SimilarProviders'

import Slider from 'apps/ProviderProfile/components/Slider'
import NavigationHeader from 'apps/ProviderProfile/components/NavigationHeader'
import Sidebar from 'apps/ProviderProfile/components/Sidebar'
import MobileBookWidget from 'apps/ProviderProfile/components/MobileBookWidget'
import InquiryDisabledMessage from 'apps/ProviderProfile/components/InquiryDisabledMessage'

import RequestStatusHint from '../components/RequestStatusHint'

import MainContent from './MainContent'

const EventSupplier = (props) => {
  const {
    provider,
    headerData,
    projectId,
  } = props

  const isPortable = useSelector(state => state.layout.portable)

  return (
    <>
      {provider.pictures && provider.pictures.length > 0 && (
        <Slider pictures={provider.pictures} title={provider.title} />
      )}

      {!isPortable && headerData && (
        <NavigationHeader
          provider={provider}
          breadcrumb={headerData.breadcrumbHtml}
          queryParams={{ projectId }}
        />
      )}

      <div className="provider-profile-page__content-wrapper">
        <MainContent provider={provider} breadcrumb={headerData && headerData.breadcrumbHtml} />

        {!isPortable && (
          <Sidebar>
            <RequestedProviderNotice provider={provider} />

            {provider.requestStatus && (
              <RequestStatusHint
                requestStatus={provider.requestStatus}
                className="provider-profile-page__content-wrapper__request-status-hint"
              />
            )}

            {!provider.canBeInquired && <InquiryDisabledMessage link={provider.filterPageUrl} />}
          </Sidebar>
        )}
      </div>

      {provider.similarProviders && (
        <SimilarProviders
          providerTitle={provider.title}
          providerCity={provider.address.city}
          providerType={provider.type}
          similarProviders={provider.similarProviders}
        />
      )}

      {isPortable && (
        <MobileBookWidget
          provider={provider}
        />
      )}
    </>
  )
}

EventSupplier.propTypes = {
  provider: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
    }),
    canBeInquired: PropTypes.bool,
    filterPageUrl: PropTypes.string,
    pictures: PropTypes.array,
    requestStatus: PropTypes.string,
    similarProviders: PropTypes.array,
    title: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  headerData: PropTypes.object,
  projectId: PropTypes.number,
}

export default EventSupplier
