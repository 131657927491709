import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'react-sticky-el'

import './Sidebar.less'

class Sidebar extends React.PureComponent {
  render () {
    return (
      <article className="provider-profile-page__sidebar">
        <Sticky
          topOffset={-90}
          boundaryElement=".provider-profile-page__sidebar"
          hideOnBoundaryHit={false}
        >
          {this.props.children}
        </Sticky>
      </article>
    )
  }
}

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Sidebar
