import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import nl2br from 'react-nl2br'

import Breadcrumb from 'components/Breadcrumb'
import CollapsibleContent from 'components/CollapsibleContent'
import RequestedProviderNotice from 'components/RequestedProviderNotice'
import FormattedMessage from 'components/FormattedMessage'

import useFlipper from 'hooks/flipper'

import CollapsibleText from '../components/MainContent/CollapsibleText'
import Header from '../components/MainContent/Header'
import Location from '../components/Location'
import Topics from '../components/MainContent/Topics'
import HybridItems from '../components/HybridItems'
import Reviews from '../components/Reviews'
import MoreLinkedProviders from '../components/MoreLinkedProviders'

import Capacity from './Capacity'
import Prices from './Prices'

const AvailabilityCalendar = dynamic(
  () => import('apps/ProviderProfile/components/AvailabilityCalendar'),
  { ssr: false },
)

const ConsultantInformation = dynamic(
  () => import('components/ConsultantInformation'),
  { ssr: false },
)

const MainContent = ({
  provider,
  breadcrumb,
  forceShowBreadcrumb,
}) => {
  const intl = useIntl()
  const isMorePricesForMeetingRoomsEnabled = useFlipper('meeting_room_prices')
  const portable = useSelector(state => state.layout.portable)

  return (
    <article id="provider-profile-page-content" className="provider-profile-page__content">
      {(portable || forceShowBreadcrumb) && (
        <>
          <Breadcrumb html={breadcrumb} />
          <RequestedProviderNotice provider={provider} />
        </>
      )}

      <CollapsibleContent collapsed={true} minHeight={295}>
        <Header
          title={provider.title}
          shortInfo={provider.shortInfo}
          isInstantBookable={provider.isInstantBookable}
          rating={provider.rating}
          reviews={provider.reviews}
          address={provider.address}
        />
      </CollapsibleContent>

      <AvailabilityCalendar providerId={provider.id.toString()} />

      {isMorePricesForMeetingRoomsEnabled && (
        <section className="provider-profile-page__content__section">
          <Prices />
        </section>
      )}

      {provider.consultantInformation && (
        <section className="provider-profile-page__content__section">
          <ConsultantInformation provider={provider} />
        </section>
      )}

      {provider.includedInRent && (
        <section className="provider-profile-page__content__section">
          <CollapsibleText
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.includedInRent' })}
            description={nl2br(provider.includedInRent)}
            descriptionAsHtml={false}
          />
        </section>
      )}

      {provider.extras && (
        <section className="provider-profile-page__content__section">
          <CollapsibleText
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.extras' })}
            description={provider.extras}
          />
        </section>
      )}

      {provider.cateringOptions && provider.cateringOptions.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.cateringOptions' })}
            topics={provider.cateringOptions}
          />
        </section>
      )}

      {provider.technicalEquippings && provider.technicalEquippings.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.technicalEquippings' })}
            topics={provider.technicalEquippings}
          />
        </section>
      )}

      {provider.amenities && provider.amenities.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.amenities' })}
            topics={provider.amenities}
          />
        </section>
      )}

      {provider.room && (
        <section className="provider-profile-page__content__section">
          <Capacity
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.capacity' })}
            room={provider.room}
          />
        </section>
      )}

      {provider.rating && (
        <section className="provider-profile-page__content__section" id="reviews">
          <Reviews rating={provider.rating} reviews={provider.reviews} />
        </section>
      )}

      {provider.address && (
        <section className="provider-profile-page__content__section">
          <Location
            title={provider.title}
            address={provider.address}
          />
        </section>
      )}

      {provider.moreRooms?.length > 0 && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={<FormattedMessage id="providerProfilePage.mainContent.linkedEventRooms" />}
            providers={provider.moreRooms}
          />
        </section>
      )}

      {provider.hybridItems?.length > 0 && (
        <section className="provider-profile-page__content__section">
          <HybridItems items={provider.hybridItems} hasHybridBadge={provider.hasHybridBadge} />
        </section>
      )}

      {provider.linkedLocation && provider.linkedLocation.path && (
        <a href={provider.linkedLocation.path} className="link link--gray">
          {intl.formatMessage({ id: 'providerProfilePage.mainContent.linkedLocationEventRoom' })}
        </a>
      )}
    </article>
  )
}

MainContent.propTypes = {
  forceShowBreadcrumb: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    moreRooms: PropTypes.array,
    linkedLocation: PropTypes.shape({
      path: PropTypes.string,
    }),
    type: PropTypes.string,
    shortInfo: PropTypes.string,
    extras: PropTypes.string,
    cateringOptions: PropTypes.array,
    isInstantBookable: PropTypes.bool,
    consultantInformation: PropTypes.object,
    rating: PropTypes.string,
    room: PropTypes.object,
    technicalEquippings: PropTypes.array,
    amenities: PropTypes.array,
    includedInRent: PropTypes.string,
    address: PropTypes.object,
    hybridItems: PropTypes.array,
    hasHybridBadge: PropTypes.bool,
    reviews: PropTypes.array,
  }).isRequired,
  breadcrumb: PropTypes.string,
}

export default MainContent
