import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import Breadcrumb from 'components/Breadcrumb'
import CollapsibleContent from 'components/CollapsibleContent'
import FormattedMessage from 'components/FormattedMessage'

import Header from '../components/MainContent/Header'
import Location from '../components/Location'
import MoreLinkedProviders from '../components/MoreLinkedProviders'
import Reviews from '../components/Reviews'

import EventIdeaDescriptions from './Descriptions'

const AvailabilityCalendar = dynamic(
  () => import('apps/ProviderProfile/components/AvailabilityCalendar'),
  { ssr: false },
)

const EventIdeaMainContent = (props) => {
  const {
    provider,
    breadcrumb,
    forceShowBreadcrumb,
  } = props

  const isTeamEvent = provider.eventIdeaData?.isTeamEvent
  const hasMoreIdeas = provider.moreIdeas && provider.moreIdeas.length > 0
  const isPortable = useSelector(state => state.layout.portable)

  return (
    <article id="provider-profile-page-content" className="provider-profile-page__content">
      {(isPortable || forceShowBreadcrumb) && <Breadcrumb html={breadcrumb} />}

      <CollapsibleContent collapsed={true} minHeight={295}>
        <Header title={provider.title} rating={provider.rating} reviews={provider.reviews} />
      </CollapsibleContent>

      <AvailabilityCalendar providerId={provider.id.toString()} />

      <EventIdeaDescriptions provider={provider} />

      {provider.rating && (
        <section className="provider-profile-page__content__section" id="reviews">
          <Reviews rating={provider.rating} reviews={provider.reviews} />
        </section>
      )}

      {provider.address && (
        <section className="provider-profile-page__content__section">
          <Location
            title={provider.address.title}
            address={provider.address}
          />
        </section>
      )}

      {hasMoreIdeas && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={(
              isTeamEvent ? (
                <FormattedMessage id="providerProfilePage.mainContent.moreTeamEvents" />
              ) : (
                <FormattedMessage id="providerProfilePage.mainContent.moreEventPackages" />
              )
            )}
            providers={provider.moreIdeas}
            className="provider-profile-page__content"
          />
        </section>
      )}

      {provider.linkedLocation && provider.linkedLocation.path && (
        <a href={provider.linkedLocation.path} className="link link--gray">
          {isTeamEvent ? (
            <FormattedMessage id="providerProfilePage.mainContent.linkedLocationTeamEvent" />
          ) : (
            <FormattedMessage id="providerProfilePage.mainContent.linkedLocationEventPackage" />
          )}
        </a>
      )}
    </article>
  )
}

EventIdeaMainContent.propTypes = {
  forceShowBreadcrumb: PropTypes.bool,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rating: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.object,
    reviews: PropTypes.array,
    eventIdeaData: PropTypes.shape({
      isTeamEvent: PropTypes.bool,
    }),
    moreIdeas: PropTypes.arrayOf(PropTypes.object),
    linkedLocation: PropTypes.shape({
      path: PropTypes.string,
    }),
  }).isRequired,
  breadcrumb: PropTypes.string.isRequired,
}

export default EventIdeaMainContent
