import React from 'react'
import PropTypes from 'prop-types'

import './Video.less'

class Video extends React.PureComponent {
  render () {
    const {
      title,
      id,
    } = this.props

    return (
      <div className="provider-profile-page__content__video">
        <h2>{title}</h2>
        <div className="video-wrapper">
          <iframe
            rel="nofollow"
            width="560"
            height="315"
            src={'https://www.youtube.com/embed/' + id}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    )
  }
}

Video.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default Video
