import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { InView } from 'react-intersection-observer'

import Spinner from 'components/Spinner'
import FormattedMessage from 'components/FormattedMessage'

import { captureException } from 'helpers/sentry'

import './index.less'

const Map = dynamic(() => import('./Map'), {
  ssr: false,
  loading: () => <Spinner fullHeight />,
})

class Location extends React.PureComponent {
  state = {
    hasError: false,
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true })

    captureException(error, { extra: info })
  }

  render () {
    const {
      title,
      address: {
        displayAddress,
        street,
        postcode,
        cityWithDistrict,
        longitude,
        latitude,
      },
    } = this.props

    if (this.state.hasError) { return null }

    return (
      <div className="provider-profile-page__location" id="location">
        <h3 className="provider-profile-page__content__headline">
          <FormattedMessage id="providerProfilePage.mainContent.address" />
        </h3>

        {displayAddress && (
          <p className="provider-profile-page__location__address">
            <strong>{title}</strong><br />
            {street}<br />
            {postcode} {cityWithDistrict}
          </p>
        )}

        <InView triggerOnce={true}>
          {({ inView, ref }) => (
            <div
              ref={ref}
              className="provider-profile-page__location__map"
            >
              {inView ? (
                <Map longitude={longitude} latitude={latitude} />
              ) : (
                <Spinner fullHeight />
              )}
            </div>
          )}
        </InView>
      </div>
    )
  }
}

Location.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
}

export default Location
