import React from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import CollapsibleContent from 'components/CollapsibleContent'
import Rating from 'components/Rating'
import { formatDate } from 'helpers/date'

import './Review.less'

const Review = (props) => {
  const {
    name,
    createdAt,
    rating,
    text,
  } = props

  return (
    <div className="provider-profile-page__reviews__review">
      <div className="provider-profile-page__reviews__review__meta">
        <Rating
          className="provider-profile-page__reviews__review__meta__rating"
          rating={rating}
          short
        />

        {name && (
          <>
            <span className="provider-profile-page__reviews__review__meta__item">
              {name}
            </span>

            <span className="provider-profile-page__reviews__review__meta__item">
              &#183;
            </span>
          </>
        )}

        <span className="provider-profile-page__reviews__review__meta__item">
          {formatDate(createdAt, 'MMMM YYYY')}
        </span>
      </div>

      <CollapsibleContent
        className="provider-profile-page__reviews__review__text"
        collapsed={true}
        minHeight={3 * 19}
        showToggleIcon={false}
      >
        {nl2br(text)}
      </CollapsibleContent>
    </div>
  )
}

Review.propTypes = {
  name: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
}

export default Review
