import React from 'react'
import Sticky from 'react-sticky-el'
import PropTypes from 'prop-types'

import Breadcrumb from 'components/Breadcrumb'
import InquiryButton from '../InquiryButton'

import './index.less'

const NavigationHeader = (props) => {
  const {
    provider,
    breadcrumb,
    queryParams,
  } = props

  const showInquiryButton = provider.canBeInquired && !['EventRoom', 'EventIdea'].includes(provider.type)

  return (
    <Sticky
      className="provider-navbar"
      boundaryElement=".provider-profile-page"
      hideOnBoundaryHit={false}
    >
      <nav>
        <div className="provider-navbar__menu">
          <Breadcrumb html={breadcrumb} />

          {showInquiryButton && (
            <InquiryButton
              provider={provider}
              className="provider-navbar__menu__cta"
              size="large"
              queryParams={queryParams}
            />
          )}
        </div>
      </nav>
    </Sticky>
  )
}

NavigationHeader.propTypes = {
  provider: PropTypes.object.isRequired,
  breadcrumb: PropTypes.string,
  queryParams: PropTypes.object,
}

export default NavigationHeader
