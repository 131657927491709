import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

const providerSchemaOrg = (provider, intl) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: provider.title,
  }

  if (provider.address) {
    schema.address = {
      '@type': 'PostalAddress',
      streetAddress: provider.address.street,
      addressLocality: provider.address.city,
      addressRegion: provider.address.cityWithDistrict,
      postalCode: provider.address.postcode,
    }
  }

  if (provider.shortInfo) {
    schema.description = provider.shortInfo
  }

  if (provider.path) {
    schema.url = `https://www.eventinc.de${provider.path}`
  }

  if (provider.pictures?.length) {
    schema.image = provider.pictures[0].url
  }

  if (provider.rating) {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      bestRating: 5,
      ratingCount: provider.ratingCount,
      ratingValue: parseFloat(provider.rating),
    }
  }

  if (provider.reviews?.length) {
    schema.review = provider.reviews.map(review => ({
      '@type': 'Review',
      author: review.displayName || intl.formatMessage({ id: 'common.anonymous' }),
      datePublished: review.createdAt,
      reviewBody: review.text,
      reviewRating: {
        '@type': 'Rating',
        bestRating: 5,
        ratingValue: parseFloat(review.rating),
      },
    }))
  }

  return JSON.stringify(schema)
}

const ProviderSchemaOrg = ({ provider }) => {
  const intl = useIntl()

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: providerSchemaOrg(provider, intl) }} />
  )
}

ProviderSchemaOrg.propTypes = {
  provider: PropTypes.shape({
    title: PropTypes.string.isRequired,
    address: PropTypes.shape({
      street: PropTypes.string,
      city: PropTypes.string,
      cityWithDistrict: PropTypes.string,
      postcode: PropTypes.string,
    }),
    shortInfo: PropTypes.string,
    rating: PropTypes.string,
    ratingCount: PropTypes.number,
    path: PropTypes.string,
    filterPageUrl: PropTypes.string,
    reviews: PropTypes.array,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      }),
    ),
  }).isRequired,
}

export default ProviderSchemaOrg
