import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { formatPrice } from 'helpers/number'

import Topics from '../components/MainContent/Topics'

class EventIdeaTopics extends React.PureComponent {
  render () {
    const {
      intl,
      eventIdeaData,
    } = this.props

    const eventIdeaDataTopics = []

    if (eventIdeaData.price) {
      const formattedPrice = formatPrice(intl, eventIdeaData.price)

      const beforePriceTranslation = intl.formatMessage({ id: 'eventIdeaData.beforePriceCapitalized' })
      const priceTranslation = intl.formatMessage({ id: 'eventIdeaData.price' }, { price: formattedPrice })
      const afterPriceTranslation = intl.formatMessage({ id: 'eventIdeaData.afterPrice' })

      eventIdeaDataTopics.push(`${beforePriceTranslation} ${priceTranslation} ${afterPriceTranslation}`)
    }

    if (eventIdeaData.catering && eventIdeaData.catering !== 'without_catering') {
      const cateringTranslation = intl.formatMessage({ id: `eventIdeaData.catering.${eventIdeaData.catering}` })
      const cateringIncludedTranslation = intl.formatMessage({ id: 'eventIdeaData.catering.included' })

      eventIdeaDataTopics.push(`${cateringTranslation} ${cateringIncludedTranslation}`)
    }

    if (eventIdeaData.attendeesMin) {
      if (eventIdeaData.attendeesMax) {
        const numberOfPersonsTranslation = intl.formatMessage(
          { id: 'eventIdeaData.numberOfPersons.fromTo' },
          { attendeesMin: eventIdeaData.attendeesMin, attendeesMax: eventIdeaData.attendeesMax },
        )

        eventIdeaDataTopics.push(numberOfPersonsTranslation)
      } else {
        const numberOfPersonsTranslation = intl.formatMessage(
          { id: 'eventIdeaData.numberOfPersons.from' },
          { attendeesMin: eventIdeaData.attendeesMin },
        )

        eventIdeaDataTopics.push(numberOfPersonsTranslation)
      }
    }

    if (eventIdeaData.durationHours) {
      const formattedDurationHours = intl.formatNumber(eventIdeaData.durationHours)
      const durationHoursTranslation = intl.formatMessage(
        { id: 'eventIdeaData.durationHours' },
        { durationHours: formattedDurationHours },
      )

      eventIdeaDataTopics.push(durationHoursTranslation)
    }

    if (!eventIdeaDataTopics.length) { return null }

    return (
      <Topics
        title={intl.formatMessage({ id: 'providerProfilePage.mainContent.locationTypes' })}
        topics={eventIdeaDataTopics}
      />
    )
  }
}

EventIdeaTopics.propTypes = {
  intl: PropTypes.object.isRequired,
  eventIdeaData: PropTypes.object.isRequired,
}

export default injectIntl(EventIdeaTopics)
