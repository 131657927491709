import React from 'react'

import Button from 'components/Button'
import PriceBadge, { PriceBadgeProps } from 'components/PriceBadge'
import Rating from 'components/Rating'
import Icon from 'components/Icon'
import Gallery from 'components/Gallery'

import { MeetingRoomProvider, EventIdeaProvider } from 'store/types/ProviderProfilePage'

import './index.less'

const LinkedProviderCard: React.FC<LinkedProviderCardProps> = (props) => {
  const {
    provider: {
      title,
      type,
      path,
      pictures,
      pricePerDay,
      guests,
      rating,
      eventIdeaData,
    },
  } = props

  let priceBadgeProps: PriceBadgeProps | undefined
  let attendees

  if (type === 'EventIdea' && eventIdeaData.price) {
    priceBadgeProps = {
      price: eventIdeaData.price,
      circa: true,
      per: 'person',
    }

    attendees = `${eventIdeaData.attendeesMin || 0} - ${eventIdeaData.attendeesMax || 0}`
  }

  if (type === 'EventRoom' && pricePerDay) {
    priceBadgeProps = {
      circa: true,
      price: pricePerDay,
      per: 'day',
    }

    attendees = guests
  }

  return (
    <a className="provider-profile-page__linked-provider-card" href={path} target="_blank">
      <div className="provider-profile-page__linked-provider-card__head">
        <Gallery
          pictures={pictures}
          title={title}
          showSlider={true}
        />

        <Button
          translationKey="providerCard.viewProfile"
          className="provider-profile-page__linked-provider-card__head__button"
        />

        {priceBadgeProps && (
          <PriceBadge {...priceBadgeProps} />
        )}
      </div>

      <div className="provider-profile-page__linked-provider-card__content">
        <span className="provider-profile-page__linked-provider-card__content__title">{title}</span>

        <div className="provider-profile-page__linked-provider-card__content__details">
          {attendees && (
            <div className="provider-profile-page__linked-provider-card__content__details__guests">
              <Icon name="user-solid" />
              {attendees}
            </div>
          )}

          {rating && (
            <Rating rating={rating} />
          )}
        </div>
      </div>
    </a>
  )
}

interface LinkedProviderCardProps {
  provider: MeetingRoomProvider & EventIdeaProvider
}

export default LinkedProviderCard
