import React from 'react'
import PropTypes from 'prop-types'

import CollapsibleContent from 'components/CollapsibleContent'
import DefaultText from './DefaultText'

class CollapsibleText extends React.PureComponent {
  render () {
    const {
      title,
      description,
      collapsed,
      minHeight,
      fadeoutText,
      descriptionAsHtml,
    } = this.props

    return (
      <CollapsibleContent collapsed={collapsed} minHeight={minHeight} fadeoutText={fadeoutText}>
        <DefaultText
          title={title}
          description={description}
          descriptionAsHtml={descriptionAsHtml}
        />
      </CollapsibleContent>
    )
  }
}
CollapsibleText.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string,
  minHeight: PropTypes.number,
  fadeoutText: PropTypes.bool,
  collapsed: PropTypes.bool,
  descriptionAsHtml: PropTypes.bool,
}

CollapsibleText.defaultProps = {
  minHeight: 300,
  fadeoutText: true,
  collapsed: true,
  descriptionAsHtml: true,
}

export default CollapsibleText
