import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from 'components/Icon'

import './index.less'

const CheckList = ({ children, className, twoColumns }) => (
  <ul className={classNames(
    'provider-profile-page__content__check-list',
    {
      'provider-profile-page__content__check-list--two-columns': twoColumns,
    },
    className,
  )} >
    {children}
  </ul>
)

CheckList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  twoColumns: PropTypes.bool,
}

export const CheckListItem = ({ children, className }) => (
  <li className={classNames('provider-profile-page__content__check-list__item', className)}>
    <Icon name="check-circle-thin" />
    <span className="provider-profile-page__content__check-list__item__label">
      {children}
    </span>
  </li>
)

CheckListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CheckList
