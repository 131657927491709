import type * as Types from '../../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProviderProfileQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  slug?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetProviderProfileQuery = { __typename?: 'Query', provider?: { __typename?: 'Provider', halfDayRatePerRoom?: any | null, halfDayRatePerPerson?: any | null, dailyRatePerRoom?: any | null, dailyRatePerPerson?: any | null, hourlyRatePerRoom?: any | null, minTurnover?: any | null } | null };


export const GetProviderProfileDocument = gql`
    query GetProviderProfile($id: ID, $slug: String) {
  provider(id: $id, slug: $slug) {
    halfDayRatePerRoom
    halfDayRatePerPerson
    dailyRatePerRoom
    dailyRatePerPerson
    hourlyRatePerRoom
    minTurnover
  }
}
    `;

/**
 * __useGetProviderProfileQuery__
 *
 * To run a query within a React component, call `useGetProviderProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetProviderProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProviderProfileQuery, GetProviderProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderProfileQuery, GetProviderProfileQueryVariables>(GetProviderProfileDocument, options);
      }
export function useGetProviderProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderProfileQuery, GetProviderProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderProfileQuery, GetProviderProfileQueryVariables>(GetProviderProfileDocument, options);
        }
export type GetProviderProfileQueryHookResult = ReturnType<typeof useGetProviderProfileQuery>;
export type GetProviderProfileLazyQueryHookResult = ReturnType<typeof useGetProviderProfileLazyQuery>;
export type GetProviderProfileQueryResult = Apollo.QueryResult<GetProviderProfileQuery, GetProviderProfileQueryVariables>;