import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedNumber } from 'react-intl'
import { useRouter } from 'next/router'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'

import './Table.less'

const Table = (props) => {
  const {
    intl,
    title,
    rooms,
  } = props

  const router = useRouter()

  function roundNumber (number) {
    if (!number) { return null }

    const parsedNumber = parseFloat(number)

    if (!Intl || !Intl.NumberFormat) {
      return <FormattedNumber value={parsedNumber} />
    }

    return new Intl.NumberFormat(router.locale).format(parsedNumber)
  }

  return (
    <div className="provider-profile-page__content__table">
      <h3 className="provider-profile-page__content__headline">
        <a name="rooms">{title}</a>
      </h3>

      <div className="table-overlay">
        <table>
          <thead>
            <tr>
              <th><FormattedMessage id="providerProfilePage.mainContent.rooms.title" /></th>
              <th><FormattedMessage id="providerProfilePage.mainContent.rooms.length" /></th>
              <th><FormattedMessage id="providerProfilePage.mainContent.rooms.width" /></th>
              <th><FormattedMessage id="providerProfilePage.mainContent.rooms.height" /></th>
              <th><FormattedMessage id="providerProfilePage.mainContent.rooms.squaremeters" /></th>
              <th><Icon name="seating-chairs_reception" /></th>
              <th><Icon name="seating-chairs_parlament" /></th>
              <th><Icon name="seating-chairs_rows" /></th>
              <th><Icon name="seating-chairs_u_shape" /></th>
              <th><Icon name="seating-chairs_block" /></th>
              <th><Icon name="seating-chairs_gala_banquet" /></th>
              <th><Icon name="seating-chairs_circle" /></th>
            </tr>
          </thead>

          <tbody>
            {rooms.map((room) => (
              <tr key={room.id}>
                <td>
                  {room.eventRoomUrl ? (
                    <div className="provider-profile-page__content__table__instant-booking">
                      <a className="link" href={room.eventRoomUrl} target="_blank">{room.title}</a>

                      <Icon
                        name="instant-booking"
                        title={intl.formatMessage({ id: 'providerProfilePage.mainContent.rooms.directBookable' })}
                      />
                    </div>
                  ) : (
                    room.title
                  )}
                </td>
                <td>{roundNumber(room.length)}</td>
                <td>{roundNumber(room.width)}</td>
                <td>{roundNumber(room.height)}</td>
                <td>{roundNumber(room.squaremeters)}</td>
                <td>{roundNumber(room.chairsReception)}</td>
                <td>{roundNumber(room.chairsParlament)}</td>
                <td>{roundNumber(room.chairsRows)}</td>
                <td>{roundNumber(room.chairsUShape)}</td>
                <td>{roundNumber(room.chairsBlock)}</td>
                <td>{roundNumber(room.chairsGalaBanquet)}</td>
                <td>{roundNumber(room.chairsCircle)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <ul className="legend">
          <li>
            <Icon name="info-circle-thin" />
            <i><FormattedMessage id="providerProfilePage.mainContent.rooms.legend" /></i>
          </li>
          <li>
            <Icon name="seating-chairs_reception" />
            <FormattedMessage id="shared.roomType.chairsReception" />
          </li>
          <li>
            <Icon name="seating-chairs_parlament" />
            <FormattedMessage id="shared.roomType.chairsParlament" />
          </li>
          <li>
            <Icon name="seating-chairs_rows" />
            <FormattedMessage id="shared.roomType.chairsRows" />
          </li>
          <li>
            <Icon name="seating-chairs_u_shape" />
            <FormattedMessage id="shared.roomType.chairsUShape" />
          </li>
          <li>
            <Icon name="seating-chairs_block" />
            <FormattedMessage id="shared.roomType.chairsBlock" />
          </li>
          <li>
            <Icon name="seating-chairs_gala_banquet" />
            <FormattedMessage id="shared.roomType.chairsGalaBanquet" />
          </li>
          <li>
            <Icon name="seating-chairs_circle" />
            <FormattedMessage id="shared.roomType.chairsCircle" />
          </li>
        </ul>
      </div>
    </div>
  )
}

Table.propTypes = {
  intl: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  rooms: PropTypes.array.isRequired,
}

export default injectIntl(Table)
