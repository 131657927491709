import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './DefaultText.less'

class DefaultText extends React.PureComponent {
  render () {
    const {
      title,
      description,
      className,
      descriptionAsHtml = true,
    } = this.props

    return (
      <div className={classNames(['provider-profile-page__content__default-text', className])}>
        {title && (
          <h3 className="provider-profile-page__content__headline">{title}</h3>
        )}

        {descriptionAsHtml ? <div dangerouslySetInnerHTML={{ __html: description }} /> : description}
      </div>
    )
  }
}

DefaultText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  descriptionAsHtml: PropTypes.bool,
}

export default DefaultText
