import React from 'react'
import PropTypes from 'prop-types'

import FormattedMessage from 'components/FormattedMessage'

import Icon from 'components/Icon'

import {
  Row,
  Col,
} from 'antd'

import './Capacity.less'

const sittingTypes = [
  {
    type: 'chairsReception',
    icon: 'seating-chairs_reception',
  },
  {
    type: 'chairsParlament',
    icon: 'seating-chairs_parlament',
  },
  {
    type: 'chairsRows',
    icon: 'seating-chairs_rows',
  },
  {
    type: 'chairsUShape',
    icon: 'seating-chairs_u_shape',
  },
  {
    type: 'chairsBlock',
    icon: 'seating-chairs_block',
  },
  {
    type: 'chairsGalaBanquet',
    icon: 'seating-chairs_gala_banquet',
  },
  {
    type: 'chairsCircle',
    icon: 'seating-chairs_circle',
  },
]

const formatSittingCount = (number) => {
  if (number) {
    return <FormattedMessage id="providerProfilePage.mainContent.capacity.guests" values={{ count: number }} />
  }

  return <FormattedMessage id="providerProfilePage.mainContent.capacity.notAvailable" />
}

const Capacity = ({
  title,
  room,
}) => (
  <div className="provider-profile-page__content__capacity">
    <h3 className="provider-profile-page__content__headline">{title}</h3>

    <Row className="provider-profile-page__content__capacity__sitting-types">
      {sittingTypes.map(sittingType => (
        <Col key={sittingType.type} span={12}>
          <Icon name={sittingType.icon} />

          <span className="provider-profile-page__content__capacity__sitting-types__text">
            <FormattedMessage id={`shared.roomType.${sittingType.type}`} />

            <span className="provider-profile-page__content__capacity__sitting-types__text__number">
              {formatSittingCount(room[sittingType.type])}
            </span>
          </span>
        </Col>
      ))}
    </Row>
  </div>
)

Capacity.propTypes = {
  title: PropTypes.string.isRequired,
  room: PropTypes.object.isRequired,
}

export default Capacity
