import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MainContent from '../components/MainContent'
import RequestStatusHint from '../components/RequestStatusHint'
import SimilarProviders from './SimilarProviders'

import MobileBookWidget from 'apps/ProviderProfile/components/MobileBookWidget'
import NavigationHeader from 'apps/ProviderProfile/components/NavigationHeader'
import Sidebar from 'apps/ProviderProfile/components/Sidebar'
import Slider from 'apps/ProviderProfile/components/Slider'
import InquiryDisabledMessage from 'apps/ProviderProfile/components/InquiryDisabledMessage'

import FormattedMessage from 'components/FormattedMessage'
import Hint from 'components/Hint'
import RequestedProviderNotice from 'components/RequestedProviderNotice'

const mapStateToProps = (state) => ({
  layout: state.layout,
})

const EventLocationProfilePage = ({ layout, provider, headerData, projectId }) => (
  <>
    {provider.pictures && provider.pictures.length > 0 && (
      <Slider pictures={provider.pictures} title={provider.title} />
    )}

    {!layout.portable && headerData &&
      <NavigationHeader
        provider={provider}
        breadcrumb={headerData.breadcrumbHtml}
        queryParams={{ projectId }}
      />
    }

    <div className="provider-profile-page__content-wrapper">
      <MainContent provider={provider} breadcrumb={headerData && headerData.breadcrumbHtml} />

      {!layout.portable && (
        <Sidebar>
          {provider.canBeInquired && provider.isConnectedToMeetingPackage && (
            <Hint variant="attention" modifiers={['bold']}>
              <FormattedMessage id="providerProfilePage.sidebar.selectRoom" />
            </Hint>
          )}

          {provider.requestStatus && (
            <RequestStatusHint
              requestStatus={provider.requestStatus}
              className="provider-profile-page__content-wrapper__request-status-hint"
            />
          )}

          <RequestedProviderNotice provider={provider} />

          {!provider.canBeInquired && <InquiryDisabledMessage link={provider.filterPageUrl} />}
        </Sidebar>
      )}
    </div>

    {provider.similarProviders && (
      <SimilarProviders
        providerTitle={provider.title}
        providerCity={provider.address.city}
        providerType={provider.type}
        similarProviders={provider.similarProviders}
      />
    )}

    {layout.portable && (
      <MobileBookWidget
        provider={provider}
      />
    )}
  </>
)

EventLocationProfilePage.propTypes = {
  layout: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  headerData: PropTypes.object,
  projectId: PropTypes.number,
}

export default connect(mapStateToProps)(EventLocationProfilePage)
