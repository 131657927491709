import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ImageGallery from 'components/ImageGallery'

import './Slider.less'

const Slider = ({ pictures, title }) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    setInitialized(true)
  })

  const imageGallery = useRef(null)

  useEffect(() => {
    const handleKeyInputForSlide = (event) => {
      if (event.key === 'ArrowLeft') { imageGallery.current.showPreviousImage() }
      if (event.key === 'ArrowRight') { imageGallery.current.showNextImage() }
    }

    window.addEventListener('keydown', handleKeyInputForSlide)

    return () => {
      window.removeEventListener('keydown', handleKeyInputForSlide)
    }
  }, [imageGallery.current])

  return (
    <div className={classNames('provider-profile-page__slider', {
      'provider-profile-page__slider--initialized': initialized,
    })}>
      <ImageGallery
        ref={imageGallery}
        pictures={pictures}
        variableWidth={true}
        adaptiveHeight={true}
        title={title}
        lazyLoad={false}
      />
      <div className="provider-profile-page__slider__shadow provider-profile-page__slider__shadow--left"></div>
      <div className="provider-profile-page__slider__shadow provider-profile-page__slider__shadow--right"></div>
    </div>
  )
}

Slider.propTypes = {
  pictures: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default Slider
