import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'

import Breadcrumb from 'components/Breadcrumb'
import CollapsibleContent from 'components/CollapsibleContent'
import RequestedProviderNotice from 'components/RequestedProviderNotice'
import FormattedMessage from 'components/FormattedMessage'

import CollapsibleText from './CollapsibleText'
import DefaultText from './DefaultText'
import Header from './Header'
import Topics from './Topics'
import Table from './Table'
import Video from './Video'

import HybridItems from '../HybridItems'
import Reviews from '../Reviews'
import Location from '../Location'
import MoreLinkedProviders from '../MoreLinkedProviders'

import './index.less'

const ConsultantInformation = dynamic(
  () => import('components/ConsultantInformation'),
  { ssr: false },
)

const AvailabilityCalendar = dynamic(
  () => import('apps/ProviderProfile/components/AvailabilityCalendar'),
  { ssr: false },
)

const MainContent = ({
  provider,
  breadcrumb,
  forceShowBreadcrumb,
}) => {
  const intl = useIntl()
  const layout = useSelector(state => state.layout)
  const hasRooms = provider.rooms && provider.rooms.length > 0
  const linkedRoomProviders = (provider.rooms || []).map(room => room.provider).filter(Boolean)

  const teamEvents = provider.eventIdeas?.filter(eventIdea => (
    eventIdea.eventCategories.find(category => category.name === 'team_building')
  ))
  const eventPackages = provider.eventIdeas?.filter(eventIdea => (
    !eventIdea.eventCategories.find(category => category.name === 'team_building')
  ))
  const hasTeamEvents = teamEvents && teamEvents.length > 0
  const hasEventPackages = eventPackages && eventPackages.length > 0

  return (
    <article id="provider-profile-page-content" className="provider-profile-page__content">
      {(layout.portable || forceShowBreadcrumb) && (
        <>
          <Breadcrumb html={breadcrumb} />
          <RequestedProviderNotice provider={provider} />
        </>
      )}

      <CollapsibleContent collapsed={true} minHeight={295}>
        <Header
          title={provider.title}
          shortInfo={provider.shortInfo}
          rating={provider.rating}
          reviews={provider.reviews}
          address={provider.address}
        />
      </CollapsibleContent>

      <AvailabilityCalendar providerId={provider.id.toString()} />

      {linkedRoomProviders.length > 0 && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={<FormattedMessage id="providerProfilePage.mainContent.linkedEventRooms.all" />}
            providers={linkedRoomProviders}
          />
        </section>
      )}

      {hasEventPackages > 0 && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={<FormattedMessage id="providerProfilePage.mainContent.linkedEventPackages" />}
            providers={eventPackages}
            className="provider-profile-page__content"
          />
        </section>
      )}

      {hasTeamEvents > 0 && (
        <section className="provider-profile-page__content__section">
          <MoreLinkedProviders
            title={<FormattedMessage id="providerProfilePage.mainContent.linkedTeamEvents.all" />}
            providers={teamEvents}
            className="provider-profile-page__content"
          />
        </section>
      )}

      {provider.essentialProperties && provider.essentialProperties.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.essentialProperties' })}
            topics={provider.essentialProperties}
          />
        </section>
      )}

      {hasRooms && (
        <section className="provider-profile-page__content__section">
          <Table
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.rooms' })}
            rooms={provider.rooms}
          />
        </section>
      )}

      {provider.consultantInformation && (
        <section className="provider-profile-page__content__section">
          <ConsultantInformation provider={provider} />
        </section>
      )}

      {provider.description && (
        <section className="provider-profile-page__content__section">
          <CollapsibleText
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.description' })}
            description={provider.description}
          />
        </section>
      )}

      {provider.cateringOptions && provider.cateringOptions.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.cateringOptions' })}
            topics={provider.cateringOptions}
          />
        </section>
      )}

      {provider.technicalEquippings && provider.technicalEquippings.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.technicalEquippings' })}
            topics={provider.technicalEquippings}
          />
        </section>
      )}

      {provider.amenities && provider.amenities.length > 0 && (
        <section className="provider-profile-page__content__section">
          <Topics
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.amenities' })}
            topics={provider.amenities}
          />
        </section>
      )}

      {provider.rating && (
        <section className="provider-profile-page__content__section" id="reviews" >
          <Reviews rating={provider.rating} reviews={provider.reviews} />
        </section>
      )}

      {provider.youtubeid && (
        <section className="provider-profile-page__content__section">
          <Video
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.video' })}
            id={provider.youtubeid}
          />
        </section>
      )}

      {provider.address && (
        <section className="provider-profile-page__content__section">
          <Location
            title={provider.title}
            address={provider.address}
          />
        </section>
      )}

      {provider.hybridItems?.length > 0 && (
        <section className="provider-profile-page__content__section">
          <HybridItems items={provider.hybridItems} hasHybridBadge={provider.hasHybridBadge} />
        </section>
      )}

      {provider.orientation && provider.orientation.length > 0 && (
        <section className="provider-profile-page__content__section">
          <DefaultText
            title={intl.formatMessage({ id: 'providerProfilePage.mainContent.orientation' })}
            description={provider.orientation}
          />
        </section>
      )}
    </article>
  )
}

MainContent.propTypes = {
  forceShowBreadcrumb: PropTypes.bool,
  provider: PropTypes.shape({
    rating: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.object,
    youtubeid: PropTypes.string,
    cateringOptions: PropTypes.array,
    technicalEquippings: PropTypes.array,
    amenities: PropTypes.array,
    rooms: PropTypes.array,
    description: PropTypes.string,
    orientation: PropTypes.string,
    essentialProperties: PropTypes.array,
    hybridItems: PropTypes.array,
    hasHybridBadge: PropTypes.bool,
    shortInfo: PropTypes.string,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    consultantInformation: PropTypes.object,
    reviews: PropTypes.array,
    eventIdeas: PropTypes.arrayOf(PropTypes.shape({
      eventCategories: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
      })).isRequired,
    })),
  }).isRequired,
  breadcrumb: PropTypes.string.isRequired,
}

export default MainContent
