import React from 'react'
import PropTypes from 'prop-types'
import Sticky from 'react-sticky-el'
import { Row, Col } from 'antd'
import { useRouter } from 'next/router'

import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import FormattedPrice from 'components/FormattedPrice'

import InquiryDisabledMessage from '../InquiryDisabledMessage'
import InquiryButton from '../InquiryButton'

import './index.less'

const MobileBookWidget = (props) => {
  const {
    provider: {
      canBeInquired,
      filterPageUrl,
      linkedLocation,
      price,
      type,
    },
  } = props

  const router = useRouter()
  const isEventIdea = type === 'EventIdea'
  const isEventRoom = type === 'EventRoom'
  const showCtaOnly = !isEventIdea && !isEventRoom
  const queryParams = { projectId: router.query.projectId }

  return (
    <Sticky
      className="provider-profile-page__mobile-book-widget"
      mode="bottom"
      boundaryElement=".provider-profile-page"
    >
      <Row gutter={20} type="flex">
        {!showCtaOnly && (
          <Col span={12} className="provider-profile-page__mobile-book-widget__secondary-col">
            {isEventIdea && (
              <div>
                <strong>
                  <FormattedMessage id="providerProfile.bookWidget.priceFrom" />
                  {' '}
                  <FormattedPrice price={price} asInteger="auto" />
                </strong>
                {' '}
                <FormattedMessage id="providerProfile.bookWidget.pricePer.person" />
              </div>
            )}

            {isEventRoom && (
              <Button
                translationKey="providerProfile.cta.secondary"
                type="link"
                href={`${linkedLocation.path}/book`}
                className="provider-profile-page__mobile-book-widget__secondary-cta"
              />
            )}
          </Col>
        )}

        <Col span={showCtaOnly ? 24 : 12}>
          {canBeInquired ? (
            <InquiryButton
              provider={props.provider}
              size="large"
              className="provider-profile-page__mobile-book-widget__cta"
              queryParams={queryParams}
            />
          ) : (
            <InquiryDisabledMessage link={filterPageUrl} />
          )}
        </Col>
      </Row>
    </Sticky>
  )
}

MobileBookWidget.propTypes = {
  provider: PropTypes.shape({
    canBeInquired: PropTypes.bool,
    filterPageUrl: PropTypes.string,
    linkedLocation: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    price: PropTypes.string,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default MobileBookWidget
